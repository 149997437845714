import RestService from '@/services/RestService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'engineeringList'
let treeListUrl = 'treeList'

class EngineeringListService extends RestService {
  defaultList (projectGuid, engineeringType = '土建') {
    return axios.get(utility.getRestFullUrl(this.resourceName, 'defaultList'), {
      params: {
        projectGuid,
        engineeringType
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  treeList (tenderGuid) {
    return axios.get(utility.getRestFullUrl(this.resourceName, treeListUrl), {
      params: {
        tenderGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const engineeringListService = new EngineeringListService(resourceName)

export default engineeringListService
