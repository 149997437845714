<template>
  <div class="g18-tender-stat">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-date-picker
            v-model="reportDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyyMMdd"
            style="width: 100%;">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleExport">导出Excel</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div class="spread-wrapper">
      <my-spread
        v-if="defaultDataList.length"
        ref="mySpread"
        :spread-info="spreadInfo"
        :default-data-list="defaultDataList"
        >
      </my-spread>
    </div>
  </div>
</template>

<script>
import g18Service from '@/services/g18Service'
import engineeringListService from '@/services/engineeringListService'
import auth from '@/common/auth'
import utility from '@/common/utility'
import MySpread from '@/components/Spread/MySpread'
import MySearchBar from '@/components/MySearchBar/MySearchBar'

export default {
  name: 'G18TenderStat',
  components: {
    MySearchBar,
    MySpread
  },
  data () {
    return {
      spreadInfo: {
        sheetName: '工程进度日统计汇总表',
        columns: [
          {
            label: '序号',
            dataKey: 'engineeringListCode',
            width: '50'
          },
          {
            label: '项目类别',
            dataKey: 'parentEngineeringListName',
            width: 200,
            autoMerge: true
          },
          {
            label: '项目名称',
            dataKey: 'engineeringListName',
            width: 200
          },
          {
            label: '单位',
            dataKey: 'unit',
            width: 90
          }
        ]
      },
      defaultDataList: [],
      engineeringList: [],
      tenderStatList: [],
      engineeringMap: {},
      otherTenderStats: [],
      reportDate: '',
      emptyColumns: [],
      engineeringType: this.$route.query.engineeringType || '土建'
    }
  },
  watch: {
    reportDate (newVal) {
      this.getData()
    }
  },
  methods: {
    handleExport () {
      console.log(this.$refs.mySpread)
      this.$refs.mySpread.exportExcel('标段工程进度日统计汇总表.xlsx')
    },
    generateMapData () {
      this.engineeringList.forEach(item => {
        this.engineeringMap[item.engineeringListCode] = {
          engineeringListGuid: item.engineeringListGuid,
          engineeringListCode: item.engineeringListCode,
          engineeringListName: item.engineeringListName,
          parentEngineeringListName: item.parentEngineeringList.engineeringListName,
          unit: item.unit,
          sortId: item.sortId
        }
      })

      let tenderNames = {}
      this.tenderStatList.forEach(item => {
        if (!tenderNames[item.tenderName]) {
          const column = {
            label: item.tenderName === 'total' ? '汇总' : item.tenderName,
            children: [
              {
                label: '合同量',
                dataKey: `${item.tenderName}_num`,
                width: 100
              },
              {
                label: '本月计划',
                dataKey: `${item.tenderName}_currentMonthPlanNum`,
                width: 100
              },
              {
                label: '本日完成',
                dataKey: `${item.tenderName}_currentDateStatNum`,
                width: 100
              },
              {
                label: '本月完成',
                dataKey: `${item.tenderName}_currentMonthStatNum`,
                width: 100
              },
              {
                label: '本月完成占月计划',
                dataKey: `${item.tenderName}_currentMonthStatPercent`,
                width: 100
              },
              {
                label: '累计完成',
                dataKey: `${item.tenderName}_totalStatNum`,
                width: 100
              },
              {
                label: '累计完成占合同',
                dataKey: `${item.tenderName}_totalStatPercent`,
                width: 100
              }
            ]
          }
          this.spreadInfo.columns.push(column)

          tenderNames[item.tenderName] = 1
        }
        console.log('item', item)
        this.engineeringMap[item.engineeringListCode][`${item.tenderName}_num`] = item.num
        this.engineeringMap[item.engineeringListCode][`${item.tenderName}_currentMonthPlanNum`] = item.currentMonthPlanNum
        this.engineeringMap[item.engineeringListCode][`${item.tenderName}_currentDateStatNum`] = item.currentDateStatNum
        this.engineeringMap[item.engineeringListCode][`${item.tenderName}_currentMonthStatNum`] = item.currentMonthStatNum
        this.engineeringMap[item.engineeringListCode][`${item.tenderName}_totalStatNum`] = item.totalStatNum
        this.engineeringMap[item.engineeringListCode][`${item.tenderName}_currentMonthStatPercent`] = item.currentMonthStatPercent
        this.engineeringMap[item.engineeringListCode][`${item.tenderName}_totalStatPercent`] = item.totalStatPercent
      })

      for (let key of Object.keys(this.engineeringMap)) {
        const item = this.engineeringMap[key]
        this.defaultDataList.push(item)
      }

      // adding other tenderstats
      if (this.otherTenderStats.length > 0) {
        let sumMap = {}
        let total = 0
        this.otherTenderStats.filter(item => item.engineeringListGuid === '0-6').forEach(item => {
          total = utility.floatAdd(total, item.content).toFixed(2)
          sumMap['parentEngineeringListName'] = '累计完成工作量'
          sumMap[`${item.tender.tenderName}_num`] = item.content
        })
        sumMap.total_num = total
        sumMap.unit = '万元'
        this.defaultDataList.unshift(sumMap)

        let otherMap = {}
        let sum = 0
        this.otherTenderStats.filter(item => item.engineeringListGuid === '0-1').forEach(item => {
          sum = utility.floatAdd(sum, item.content).toFixed(2)
          otherMap['parentEngineeringListName'] = '当日完成工作量'
          otherMap[`${item.tender.tenderName}_num`] = item.content
        })
        otherMap.total_num = sum
        otherMap.unit = '万元'
        this.defaultDataList.unshift(otherMap)

        const nameArray = [
          '驻地、拌合站、梁场建设完成情况',
          '主要材料进场情况',
          '施工作业面开展情况',
          '项目完成情况说明'
        ]
        for (let i = 2; i <= 5; ++i) {
          let bottomMap = {}
          this.otherTenderStats.filter(item => item.engineeringListGuid === `0-${i}`).forEach(item => {
            bottomMap['parentEngineeringListName'] = nameArray[i - 2]
            bottomMap[`${item.tender.tenderName}_num`] = item.content
          })
          this.defaultDataList.push(bottomMap)
        }
      }

      // 合并单元格
      this.$nextTick(() => {
        const rowIndexArray = [0, 1, this.defaultDataList.length - 1, this.defaultDataList.length - 2, this.defaultDataList.length - 3, this.defaultDataList.length - 4]
        for (let i = 0; i < rowIndexArray.length; ++i) {
          const rowIndex = rowIndexArray[i]
          this.$refs.mySpread.worksheet.addSpan(rowIndex, 1, 1, 2)
          const start = 4
          for (let j = 0; start + j * 7 < this.$refs.mySpread.dataColumns.length; ++j) {
            this.$refs.mySpread.worksheet.addSpan(rowIndex, start + 7 * j, 1, 7)
          }
        }
      })

      this.$nextTick(() => {
        console.log('this.dataColumns', this.$refs.mySpread.dataColumns)
        this.emptyColumns.forEach(tenderName => {
          let columnIndex = this.$refs.mySpread.dataColumns.findIndex(column => column.dataKey === `${tenderName}_currentDateNum`)
          this.$refs.mySpread.setError(-1, columnIndex)
        })
      })

      this.$loading().close()
    },
    getEngineeringList () {
      engineeringListService.defaultList(auth.getUserInfo().projectGuid, this.engineeringType)
        .then(res => {
          if (res.data.code === 1) {
            this.engineeringList = res.data.data.filter(item => item.parentId !== 0)
            this.generateMapData()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '加载失败，请重试！'
          })
        })
    },
    getData () {
      this.$myLoading()
      this.tenderStatList = []
      this.defaultDataList = []
      this.otherTenderStats = []
      this.spreadInfo.columns = [
        {
          label: '序号',
          dataKey: 'engineeringListCode',
          width: '50'
        },
        {
          label: '项目类别',
          dataKey: 'parentEngineeringListName',
          width: 200,
          autoMerge: true
        },
        {
          label: '项目名称',
          dataKey: 'engineeringListName',
          width: 200
        },
        {
          label: '单位',
          dataKey: 'unit',
          width: 90
        }
      ]
      if (!this.reportDate) {
        this.$loading().close()
        return
      }
      g18Service.getDateTenderStat(this.reportDate, auth.getUserInfo().projectGuid, this.engineeringType)
        .then(res => {
          if (res.data.code === 1) {
            this.tenderStatList = res.data.data
            console.log(this.tenderStatList)

            this.getOtherTenderStat()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '加载失败，请重试！'
          })
        })
    },
    getOtherTenderStat () {
      g18Service.getOtherTenderStat(auth.getUserInfo().projectGuid, this.reportDate, this.engineeringType)
        .then(res => {
          if (res.data.code === 1) {
            this.otherTenderStats = res.data.data
            this.getEngineeringList()
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '加载列表失败'
          })
        })
    }
  },
  created () {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.g18-tender-stat {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spread-wrapper {
    flex: 1;
  }
}
</style>
